const docReady = (fn) => {
  // see if DOM is already available
  if (document.readyState === "complete" || document.readyState === "interactive") {
    // call on next available tick
    setTimeout(fn, 1);
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
};

const enableScrollers = () => {
  document.querySelectorAll(".vertically-scrollable").forEach((el) => {
    el.addEventListener("scroll", function (evt) {
      if (this.scrollTop == 0) {
        el.closest(".inner-shadowed")
          .querySelectorAll(":scope > .inner-shadow.inner-shadow-top.inner-shadow-show")
          .forEach((obj) => {
            obj.classList.remove("inner-shadow-show");
          });
        evt.target.classList.add("scrolled-to-top");
      } else {
        el.closest(".inner-shadowed")
          .querySelectorAll(":scope > .inner-shadow.inner-shadow-top:not(.inner-shadow-show)")
          .forEach((obj) => {
            obj.classList.add("inner-shadow-show");
          });
        evt.target.classList.remove("scrolled-to-top");
      }

      if (this.scrollTop == this.scrollHeight - this.clientHeight) {
        el.closest(".inner-shadowed")
          .querySelectorAll(":scope > .inner-shadow.inner-shadow-bottom.inner-shadow-show")
          .forEach((obj) => {
            obj.classList.remove("inner-shadow-show");
          });
        evt.target.classList.add("scrolled-to-bottom");
      } else {
        el.closest(".inner-shadowed")
          .querySelectorAll(":scope > .inner-shadow.inner-shadow-bottom:not(.inner-shadow-show)")
          .forEach((obj) => {
            obj.classList.add("inner-shadow-show");
          });
        evt.target.classList.remove("scrolled-to-bottom");
      }
    });
  });

  document.querySelectorAll(".horizontally-scrollable").forEach((el) => {
    el.addEventListener("scroll", function (evt) {
      if (this.scrollLeft == 0) {
        el.closest(".inner-shadowed")
          .querySelectorAll(":scope > .inner-shadow.inner-shadow-left.inner-shadow-show")
          .forEach((obj) => {
            obj.classList.remove("inner-shadow-show");
          });
        evt.target.classList.add("scrolled-to-left");
      } else {
        el.closest(".inner-shadowed")
          .querySelectorAll(":scope > .inner-shadow.inner-shadow-left:not(.inner-shadow-show)")
          .forEach((obj) => {
            obj.classList.add("inner-shadow-show");
          });
        evt.target.classList.remove("scrolled-to-left");
      }

      if (this.scrollLeft == this.scrollWidth - this.clientWidth) {
        el.closest(".inner-shadowed")
          .querySelectorAll(":scope > .inner-shadow.inner-shadow-right.inner-shadow-show")
          .forEach((obj) => {
            obj.classList.remove("inner-shadow-show");
          });
        evt.target.classList.add("scrolled-to-right");
      } else {
        el.closest(".inner-shadowed")
          .querySelectorAll(":scope > .inner-shadow.inner-shadow-right:not(.inner-shadow-show)")
          .forEach((obj) => {
            obj.classList.add("inner-shadow-show");
          });
        evt.target.classList.remove("scrolled-to-right");
      }
    });
  });
};

const enablePopovers = () => {
  document.querySelectorAll("[data-bs-toggle=popover]").forEach((el) => {
    new bootstrap.Popover(el);
  });
};

const getParentByQuerySelector = (node, query) => {
  let result = null;
  while (node) {
    if (node.parentNode && node.parentNode.parentNode) {
      if ((result = node.parentNode.parentNode.querySelector(query))) {
        break;
      }
    }
    node = node.parentNode;
  }
  return result;
};

const showModal = (id, content, remove = false) => {
  document.body.insertAdjacentHTML("beforeend", content);
  const modalDiv = document.getElementById(id);
  const modal = new bootstrap.Modal(modalDiv);
  modalDiv.addEventListener("hidden.bs.modal", () => {
    if (remove) modalDiv.remove();
  });
  modal.show();
};

const useDebouncer = (groupName, timeout, onTimeoutComplete, onTimeoutCancelled, onReset) => {
  window.__useDebouncerData ||= {};

  const __debouncerHasTimer = () => !!window.__useDebouncerData[groupName];

  return {
    signalStart: (data) => {
      if (!__debouncerHasTimer()) {
        // console.log(groupName, "debouncer started")
        window.__useDebouncerData[groupName] = setTimeout(() => onTimeoutComplete(data), timeout);
      } else {
        // console.log(groupName, "debouncer already started")
      }
    },
    signalCancel: (data) => {
      if (__debouncerHasTimer()) {
        // console.log(groupName, "debouncer cancelled")
        clearTimeout(window.__useDebouncerData[groupName]);
        window.__useDebouncerData[groupName] = null;
        if (onTimeoutCancelled) {
          onTimeoutCancelled(data);
        }
      } else {
        // console.log(groupName, "debouncer impossible to cancel: no timer present")
      }
    },
  };
};

const formatCurrencyValue = (value, currency = "EUR", locale = "it") => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).format(value);
};

const formatTimeToString = (minutesTime, format = "time") => {
  if (isNaN(minutesTime)) {
    return undefined;
  }

  if (format === "text" || format === "text-short") {
    const minutes = minutesTime % 60;
    const hours = Math.round((minutesTime - minutes) / 60);
    const short = format === "text-short";

    let minText = minutes > 0 ? (short ? `${minutes}m` : `${minutes} min`) : null;
    let hrsText = hours > 0 ? (short ? `${hours}h` : `${hours} ore`) : null;
    if (minutes == 0 && hours == 0) {
      hrsText = short ? "0h" : "0 ore";
    }
    return [hrsText, minText].filter((x) => x).join(" ");
  } else {
    const minutes = minutesTime % 60;
    const hours = Math.round((minutesTime - minutes) / 60);
    const formattedHours = (hours < 10 ? "0" : "") + hours;
    const formattedMinutes = (minutes < 10 ? "0" : "") + minutes;

    return `${formattedHours}:${formattedMinutes}`;
  }
};

export {
  docReady,
  enableScrollers,
  enablePopovers,
  showModal,
  useDebouncer,
  getParentByQuerySelector,
  formatCurrencyValue,
  formatTimeToString,
};
